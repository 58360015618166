<template>
  <div class="w-100 h-100">
    <PieChart
      :is-loading="isLoading"
      :chart-data="chartData"
      :title="title"
      :colors="colors"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      title: 'Job Platform Click Statistics',
      chartData: {},
      colors: [
            this.$themeColors.danger,
            this.$themeColors.primary,
            this.$themeColors.success,
      ],
    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post(`/api/event-reports/analytics`, {
          form_data: {
            fetch_data: ['platform_chart'],
            report_id: reportId,
          }
        })
        .then(response => {
            const { reports } = response.data
            this.chartData = {}
            Object.entries(reports['platform_chart']).forEach(([key, value], index) => {
                this.chartData[this.$capitalizeString(this.$removeUnderscore(key))] = value
            })

            this.isLoading = false
        })
          .catch(error => {
            console.error(error)
          this.isLoading = false
        })
    }
  },
}
</script>

<style>

</style>