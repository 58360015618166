<template>
  <div>
    <!-- <h2>Status</h2> -->
    <!-- <b-row v-if="reportData.statistics"> -->
    <div v-if="eventId !== kioskEventID">
      <b-row v-if="reportData.statistics && eventId">
        <b-col v-if="reportData.statistics.Hired">
          <statistic-card-vertical
            color="success"
            icon="CheckIcon"
            :statistic="reportData.statistics.Hired"
            statistic-title="Hired"
          />
        </b-col>

        <b-col v-if="reportData.statistics.Shortlisted">
          <statistic-card-vertical
            color="warning"
            icon="ListIcon"
            :statistic="reportData.statistics.Shortlisted"
            statistic-title="Shortlisted "
          />
        </b-col>

        <b-col v-if="reportData.statistics.Rejected">
          <statistic-card-vertical
            color="danger"
            icon="XIcon"
            :statistic="reportData.statistics.Rejected"
            statistic-title="Unsuccessful"
          />
        </b-col>

        <b-col v-if="reportData.statistics['Kept In View']">
          <statistic-card-vertical
            color="warning"
            icon="ClockIcon"
            :statistic="reportData.statistics['Kept In View']"
            statistic-title="Pending"
          />
        </b-col>

        <b-col v-if="reportData.statistics.Unknown">
          <statistic-card-vertical
            color="danger"
            icon="AlertTriangleIcon"
            :statistic="reportData.statistics.Unknown"
            statistic-title="Unknown"
          />
        </b-col>
      </b-row>
      <hr />

      <b-row v-if="reportData.statistics" class="mt-1">
        <!-- Page Visits -->
        <b-col cols="12" xl="4" md="4" sm="6">
          <div>
            <AnalyticsAverageBounceRate />
          </div>
          <div class="">
            <AnalyticsJobApplicationStatistics />
          </div>
        </b-col>

        <b-col cols="12" xl="8" md="8" sm="6" class="">
          <div>
            <AnalyticsAverageSessionDuration />
          </div>
          <div class="">
            <AnalyticsEventPageViewsStatistics />
          </div>
        </b-col>

        <b-col cols="12" xl="12" md="12" sm="12">
          <div>
            <AnalyticsJobViewsStatistics />
          </div>
        </b-col>

        <b-col cols="12" xl="12" md="12" sm="12">
          <div>
            <AnalyticsInterestedApplicants />
          </div>
        </b-col>

        <b-col v-if="!eventId" cols="12" xl="6" md="6">
          <div>
            <AnalyticsPageVisits />
          </div>
        </b-col>

        <!-- TRACKING ANALYTICS -->
        <b-col v-if="!eventId" cols="12" xl="6" md="6">
          <div>
            <AnalyticsTracking />
          </div>
        </b-col>

        <!-- Source of Visitor (UTM) -->
        <b-col cols="12" xl="12" md="12">
          <div>
            <AnalyticsSourceOfVisitor />
          </div>
        </b-col>
      </b-row>

      <h3 v-if="!eventId">Job Seeker</h3>

      <b-row v-if="reportData.statistics && !eventId">
        <b-col v-if="reportData.statistics.total_jobs">
          <statistic-card-horizontal
            icon="BriefcaseIcon"
            :statistic="reportData.statistics.total_jobs"
            statistic-title="Total Jobs"
          />
        </b-col>
        <b-col v-if="reportData.statistics.total_applicants">
          <statistic-card-horizontal
            icon="UserIcon"
            :statistic="reportData.statistics.total_applicants"
            statistic-title="Total Applicants"
          />
        </b-col>
        <b-col v-if="reportData.statistics.total_applications">
          <statistic-card-horizontal
            icon="CalendarIcon"
            :statistic="reportData.statistics.total_applications"
            statistic-title="Scheduled Interview"
          />
        </b-col>
        <b-col v-if="reportData.statistics.all_interviews_completed">
          <statistic-card-horizontal
            variant="success"
            icon="CheckIcon"
            :statistic="reportData.statistics.all_interviews_completed"
            statistic-title="Interview Completed"
          />
        </b-col>
      </b-row>

      <b-row v-if="!eventId">
        <!-- <b-col
        cols="12" xl="6" md="12"
      >
        <number-of-applicant-chart
          :report-data="reportData"
          :which-page="'summary'"
        />
      </b-col> -->

        <!-- <b-col
        cols="12" xl="6" md="6"
      >
        <popular-company :data="reportData.popular_companies" />
      </b-col> -->

        <b-col cols="12" xl="3" md="6">
          <div>
            <AnalyticsGenderChart />
          </div>
        </b-col>

        <b-col cols="12" xl="3" md="6">
          <div>
            <AnalyticsEmploymentStatusChart />
          </div>
        </b-col>

        <b-col cols="12" xl="3" md="6">
          <div>
            <AnalyticsCitizenshipChart />
          </div>
        </b-col>

        <b-col cols="12" xl="3" md="6">
          <div>
            <AnalyticsQualificationLevelChart />
          </div>
        </b-col>

        <b-col cols="12" xl="6" md="6">
          <div>
            <AnalyticsNumberOfJobSeeker />
          </div>
          <b-col class="m-0 p-0" cols="12" xl="12" md="12">
            <div>
              <AnalyticsLoginActivityJobSeeker />
            </div>
          </b-col>
        </b-col>

        <b-col cols="12" xl="6" md="6">
          <b-col class="m-0 p-0" cols="12" xl="12" md="12">
            <div>
              <AnalyticsJobApplications />
            </div>
            <ChartComponent
              v-if="false"
              :component-options="{
                cardHeight: '60vh',
              }"
              :report-data="reportData"
              :which-page="'summary'"
              title="Job Applications - 30 days"
              section="job_seeker"
              data-name="job_applications_chart"
            />
          </b-col>
        </b-col>

        <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <interview-type-chart :report-data="reportData" />
      </b-col> -->
      </b-row>

      <h3 v-if="!eventId">Employer</h3>

      <b-row v-if="!eventId">
        <!-- <b-col
        cols="12" xl="3" md="6"
      >
        <gender-chart :report-data="reportData" section="employer"/>
      </b-col>
      <b-col
        cols="12" xl="3" md="6"
      >
        <employment-status-chart :report-data="reportData" section="employer"/>
      </b-col> -->

        <b-col cols="12" xl="6" md="6">
          <div>
            <AnalyticsNumberOfEmployer />
          </div>
          <b-col class="m-0 p-0" cols="12" xl="12" md="12">
            <div>
              <AnalyticsLoginActivityEmployer />
            </div>
          </b-col>
        </b-col>

        <b-col cols="12" xl="6" md="6">
          <b-col class="m-0 p-0" cols="12" xl="12" md="12">
            <div>
              <AnalyticsActiveJobs />
            </div>
          </b-col>
        </b-col>
      </b-row>
    </div>

    <div v-else>
       <b-row v-if="reportData.statistics">
        <b-col cols="4">
           <KioskAnalyticsPlatformStatistics />
        </b-col>
        <b-col cols="4">
         <TopKioskJobsStatistics />
        </b-col>
        
        
        
      </b-row>

    </div>
  </div>
</template>

<script>
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

import CitizenshipChart from "./CitizenshipChart.vue";
import EmploymentStatusChart from "./EmploymentStatusChart.vue";
import GenderChart from "./GenderChart.vue";
import EventPageChart from "./EventPageChart.vue";
import EventActionsChart from "./EventActionsChart.vue";
import NumberOfApplicantChart from "./NumberOfApplicantChart.vue";
import PopularCompany from "./PopularCompany.vue";
import QualificationLevelList from "./QualificationLevelList.vue";

import CustomAnalyticCard from "./CustomAnalyticCard.vue";

import ChartComponent from "./ChartComponent.vue";
import AnalyticsAverageBounceRate from "./standalones/AnalyticsAverageBounceRate.vue";
import AnalyticsJobApplicationStatistics from "./standalones/AnalyticsJobApplicationStatistics.vue";
import KioskAnalyticsPlatformStatistics from "./standalones/KioskAnalyticsPlatformStatistics.vue"
import TopKioskJobsStatistics from "./standalones/TopKioskJobsStatistics.vue"
import AnalyticsAverageSessionDuration from "./standalones/AnalyticsAverageSessionDuration.vue";
import AnalyticsEventPageViewsStatistics from "./standalones/AnalyticsEventPageViewsStatistics.vue";
import AnalyticsJobViewsStatistics from "./standalones/AnalyticsJobViewsStatistics.vue";
import AnalyticsInterestedApplicants from "./standalones/AnalyticsInterestedApplicants.vue";
import AnalyticsPageVisits from "./standalones/AnalyticsPageVisits.vue";
import AnalyticsTracking from "./standalones/AnalyticsTracking.vue";
import AnalyticsSourceOfVisitor from "./standalones/AnalyticsSourceOfVisitor.vue";

//* Job Seeker
import AnalyticsGenderChart from "./standalones/AnalyticsGenderChart.vue";
import AnalyticsEmploymentStatusChart from "./standalones/AnalyticsEmploymentStatusChart.vue";
import AnalyticsCitizenshipChart from "./standalones/AnalyticsCitizenshipChart.vue";
import AnalyticsQualificationLevelChart from "./standalones/AnalyticsQualificationLevelChart.vue";
import AnalyticsNumberOfJobSeeker from "./standalones/AnalyticsNumberOfJobSeeker.vue";
import AnalyticsLoginActivityJobSeeker from "./standalones/AnalyticsLoginActivityJobSeeker.vue";
import AnalyticsJobApplications from "./standalones/AnalyticsJobApplications.vue";

//* Employer
import AnalyticsNumberOfEmployer from "./standalones/AnalyticsNumberOfEmployer.vue";
import AnalyticsLoginActivityEmployer from "./standalones/AnalyticsLoginActivityEmployer.vue";
import AnalyticsActiveJobs from "./standalones/AnalyticsActiveJobs.vue";

export default {
  components: {
    StatisticCardHorizontal,
    StatisticCardVertical,

    CitizenshipChart,
    EmploymentStatusChart,
    GenderChart,
    EventPageChart,
    EventActionsChart,
    NumberOfApplicantChart,
    PopularCompany,
    QualificationLevelList,

    CustomAnalyticCard,

    ChartComponent,
    AnalyticsAverageBounceRate,
    AnalyticsJobApplicationStatistics,
    KioskAnalyticsPlatformStatistics,
    TopKioskJobsStatistics,
    AnalyticsAverageSessionDuration,
    AnalyticsEventPageViewsStatistics,
    AnalyticsJobViewsStatistics,
    AnalyticsInterestedApplicants,
    AnalyticsPageVisits,
    AnalyticsTracking,
    AnalyticsSourceOfVisitor,
    AnalyticsGenderChart,
    AnalyticsEmploymentStatusChart,
    AnalyticsCitizenshipChart,
    AnalyticsQualificationLevelChart,
    AnalyticsNumberOfJobSeeker,
    AnalyticsLoginActivityJobSeeker,
    AnalyticsJobApplications,

    AnalyticsNumberOfEmployer,
    AnalyticsLoginActivityEmployer,
    AnalyticsActiveJobs,
  },
  props: ["reportData"],
  data() {
    return {
      hide_temporary: false,
      eventId: null,
      kioskEventID: "f292b734-354a-44db-86e3-3adf0e38c37a",
    };
  },
  created() {
    this.eventId = this.$route.params.report_name.split("__")[1];
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.custom-table {
  width: 100%;

  thead {
    line-height: 3.5rem;
    background: $primary;
    color: $white;
  }
  tbody {
    line-height: 3rem;
  }

  tbody tr:nth-child(even) {
    background: lighten($primary, 48%);
  }

  thead td {
    padding-inline: 1rem;
  }

  tbody td {
    padding-inline: 1rem;
  }
  .row-summary {
    background: lighten($primary, 40%);
    font-weight: bolder;
    font-size: 1.5rem;
    line-height: 3.5rem;
  }
}
</style>
